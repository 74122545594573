import styles from './addTrader.module.css';
import { Button, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import apiClient from '../../lib/apiClient';

const AddTrader = () => {
  const defaultData = {
    mode: "TESTING",
    leverage: 100,
    type: "UNLIMITED",
    takeProfitStep: 1,
  }
  const [open, setOpen] = useState(false);
  const [addTrader, setAddTrader] = useState(defaultData);

  const dismiss = async() => {
    setAddTrader(defaultData);
    setOpen(false);
  }

  const createTrader = async() => {
    try {
      if(!addTrader) {
        dismiss();
      }
      const {symbol, baseAmountIn, maxBaseAmountIn, takeProfit, stepSize, stopLoss, mode, leverage, aim, type, lives, hours, coverage, takeProfitStep} = addTrader;
      const data = {
        symbol,
        baseAmountIn,
        maxBaseAmountIn,
        takeProfit,
        stepSize,
        stopLoss,
        mode,
        leverage,
        aim,
        type,
        lives, 
        hours, 
        coverage, 
        takeProfitStep
      }
      const response = await apiClient.post("/", data);
      console.log(response);
      dismiss();
    }
    catch(error) {
      console.log(error);
      window.location.reload();
    }
  }

  return (
    <div className={styles.addTrader}>
      <Button type="primary" style={{ color: "#fff" }} onClick={() => setOpen(true)}>
        Add Trader
      </Button>
      <Modal
        title="Add Trader"
        open={open}
        okText={"Create"}
        onOk={createTrader}
        onCancel={() => setOpen(false)}
        okButtonProps = {{
          disabled: !addTrader || !addTrader?.symbol || !addTrader?.stepSize || !addTrader?.takeProfit,
          style: {color: "#fff"}
        }}
      >
        <Input placeholder='Symbol' value={addTrader?.symbol || ""} onChange={(e) => setAddTrader(old => ({...old, symbol: e.target.value}))}/> 
        <Input placeholder='Start Amount' type="number" value={addTrader?.baseAmountIn || ""} onChange={(e) => setAddTrader(old => ({...old, baseAmountIn: e.target.value}))}/> 
        <Input placeholder='Max Amount' type="number" value={addTrader?.maxBaseAmountIn || ""} onChange={(e) => setAddTrader(old => ({...old, maxBaseAmountIn: e.target.value}))}/> 
        <Input placeholder='Leverage' type="number" value={addTrader?.leverage} onChange={(e) => setAddTrader(old => ({...old, leverage: Number(e.target.value)}))}/> 
        <Input placeholder='Take Profit' type="number" value={addTrader?.takeProfit || ""} onChange={(e) => setAddTrader(old => ({...old, takeProfit: e.target.value}))}/> 
        <Input placeholder='Stop Loss' type="number" value={addTrader?.stopLoss || ""} onChange={(e) => setAddTrader(old => ({...old, stopLoss: e.target.value}))}/> 
        <Input placeholder='Step Size' type="number" value={addTrader?.stepSize || ""} onChange={(e) => setAddTrader(old => ({...old, stepSize: e.target.value}))}/> 
        {/*<Input placeholder='Take Profit Step' type="number" value={addTrader?.takeProfitStep || ""} onChange={(e) => setAddTrader(old => ({...old, takeProfitStep: e.target.value}))}/>*/}
        <div className={styles.inputContainer}>
          <Select defaultValue="TESTING" style={{width: "100%"}} options={[{label: "Testing", value: "TESTING"}, {label: "Live", value: "LIVE"}]} onChange={(val) => setAddTrader(old => ({...old, mode: val}))}/>
        </div>
      </Modal>
    </div>
  );
}

export default AddTrader;
