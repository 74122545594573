import styles from './line.module.css';

const Line = ({data}) => {

  return (
    <div className={`${styles.lineContainer} ${data.status === "NEW" || !data?.status ? styles.pendingOrder : ""}`}>
        <span className={`${styles.profit} ${Number(data.profit) > 0 ? styles.green : styles.red}`}>{data.side === "PRICE" ? "" : Number(data.profit).toFixed(2)}</span>
        <span className={`${styles.body} ${data.side === "LONG" ? styles.long : data.side === "PRICE" ? styles.priceBody : styles.short}`}></span>
        <span className={`${styles.gold}`}>{data.baseAmountIn.toFixed(3)}</span>
    </div>
  );
}

export default Line;
